//import axios
import axios from 'axios';

const Api = axios.create({
  //set default endpoint API
  // baseURL: 'https://backend-donasi.rplidnsolo.com/api'
  baseURL: process.env.VUE_APP_API_URL || 'https://donasi.tebarquran.com/api',
  // baseURL: 'http://127.0.0.1:8000/api'
});

export default Api;
