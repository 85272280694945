<template>
  <div>
    <!-- header -->
    <header>
      <div class="fixed inset-x-0 top-0 z-10 text-center text-white bg-primary">
        <div class="container flex items-center gap-3 p-3 mx-auto sm:w-full md:w-5/12">
          <!-- <div class="w-10 p-1 my-auto bg-white rounded-full"> -->
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/images/logo-white.png" class="inline-block w-14" />
          </router-link>
          <!-- </div> -->

          <form class="w-full">
            <div class="relative">
              <input
                type="text"
                @click="linkToSearch"
                v-model="search"
                @keyup="searchQuery"
                class="w-full h-2 p-5 placeholder-white rounded-full appearance-none bg-primary-lighter focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600"
                placeholder="Cari yang ingin kamu bantu"
              />
              <div
                class="absolute inset-y-0 flex items-center justify-end pointer-events-none right-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </form>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
//hook vue
import { ref } from 'vue';

//hook vue router
import { useRouter } from 'vue-router';

//hook vuex
import { useStore } from 'vuex';

export default {
  name: 'HeaderComponent',

  setup() {
    //router
    const router = useRouter();

    //store
    const store = useStore();

    //state seacrh
    const search = ref(null);

    //queryString
    function searchQuery() {
      store.dispatch('campaign/searchCampaign', search.value);
    }

    //redirect to route search
    function linkToSearch() {
      router.push({
        name: 'search',
      });
    }

    return {
      search, // <-- state search
      linkToSearch, // <-- method linkToSearch
      searchQuery, // <-- method searchQuery
    };
  },
};
</script>

<style></style>
